import React, { useEffect, useState } from "react";
import "./Home.css";
import axios from "axios";

const Home = () => {
  const [axiosData, setAxiosData] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [addedUser, setAddedUser] = useState({
    name: "",
  });
  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: "asc",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get(
        "https://api.togetherwecandosomuchmore.nl/uierboord/all"
      );

      console.log(response.data);

      setAxiosData(response.data);
    };

    fetchUsers();
  }, [trigger]);

  const changeAttending = async (id) => {
    await axios.get(
      `https://api.togetherwecandosomuchmore.nl/uierboord/change-attending/${id}`
    );
    setTrigger(Math.random());
  };

  const removeUser = async (id) => {
    await axios.get(
      `https://api.togetherwecandosomuchmore.nl/uierboord/remove-user/${id}`
    );
    setTrigger(Math.random());
  };

  const addUser = async () => {
    await axios.post(
      "https://api.togetherwecandosomuchmore.nl/uierboord/create-single-user",
      {
        name: addedUser.name ? addedUser.name : "null",
      }
    );

    window.location.reload();
  };

  const totalAttending = () => {
    const totalAttendingNumber = axiosData?.filter((u) => u.attending === 1);
    return totalAttendingNumber ? totalAttendingNumber.length : "N/A";
  };

  const handleSort = (column) => {
    setSortOrder((prevSortOrder) => ({
      column,
      direction:
        column === prevSortOrder.column && prevSortOrder.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const sortedData = axiosData
    ? [...axiosData].sort((a, b) => {
        if (sortOrder.column === "name") {
          const getLastSpaceIndex = (name) => {
            const spaceIndex = name.lastIndexOf(" ");
            return spaceIndex !== -1 ? spaceIndex : 0;
          };

          const charA = a.name
            .charAt(getLastSpaceIndex(a.name) + 1)
            .toLowerCase();
          const charB = b.name
            .charAt(getLastSpaceIndex(b.name) + 1)
            .toLowerCase();

          // Compare attending status first, then alphabetically
          if (a.attending !== b.attending) {
            return sortOrder.direction === "asc"
              ? b.attending - a.attending
              : a.attending - b.attending;
          } else {
            // Adjusted sorting based on the first character after the last space
            return sortOrder.direction === "asc"
              ? charA.localeCompare(charB)
              : charB.localeCompare(charA);
          }
        }
        return 0;
      })
    : [];

  return (
    <div className="table-container">
      <h1>Uierboord 2024 Dashboard</h1>
      <h4>
        Total Count: {axiosData ? axiosData?.length : "N/A"}{" "}
        <span className="divider">|</span> Total Attending: {totalAttending()}
      </h4>

      <div className="new-user-container">
        <h5>Add New User</h5>
        <div className="add-new-user">
          <input
            onChange={(e) =>
              setAddedUser({ ...addedUser, name: e.target.value })
            }
            type="text"
            placeholder="Name"
          />
          <button className="add-user-btn" onClick={() => addUser()}>
            Add
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th
              onClick={() => handleSort("name")}
              style={{ cursor: "pointer" }}
            >
              Name{" "}
              {sortOrder.column === "name" && sortOrder.direction === "asc"
                ? "▲"
                : "▼"}
            </th>
            <th>Attending</th>
            <th>Remove from list</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((user) => (
            <tr key={user._id}>
              {/* <td>{user._id}</td> */}
              <td>{user.name}</td>
              <td
                className={`isAttending ${
                  user.attending === 0 ? "notAttending" : "attending"
                }`}
              >
                {user.attending === 0 ? "No" : "Yes"}{" "}
                <span
                  onClick={() => changeAttending(user._id)}
                  className="change-btn"
                >
                  Change
                </span>
              </td>
              <td>
                <span
                  onClick={() => removeUser(user._id)}
                  className="remove-btn"
                >
                  Remove
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Home;
