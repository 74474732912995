import React, { useState, useEffect } from "react";
import Home from "./Home";
import "./App.css"

export default function App() {
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("auth") === "1"
  );

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    // Check if the entered password is correct
    if (password === "GTM2024") {
      setAuthenticated(true);
      localStorage.setItem("auth", "1"); // Set local storage to indicate authentication
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  useEffect(() => {
    // Clear local storage on component unmount (cleanup)
    return () => {
      localStorage.removeItem("auth");
    };
  }, []);

  return (
    <div className="App">
      {!authenticated ? (
        <div style={{ textAlign: 'center' }}>
          <label>Password: </label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button className="login-btn" style={{ marginLeft: '20px' }} onClick={handleLogin}>Login</button>
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
}
